<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/sales/contracts')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="date" class="form-label required">
            تاريخ العقد
            <span>*</span>
          </label>

          <Calendar
            style="width: 100%;"
            v-model="body.date"
            :class="{ 'is-invalid': isInvalid && !body.date }"
            dateFormat="dd-mm-yy"
            :minDate="minDateValue"
            required
          />
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="mb-3 ">
          <label for="code" class="form-label">
            رقم العقد
          </label>
          <input class="form-control" id="code" v-model="body.code" />
        </div>
      </div> -->

      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="branchesId" class="form-label required">
            الفرع
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="branchesId"
            name="branchesId"
            v-model.number="body.branchesId"
            :class="{ 'is-invalid': isInvalid && !body.branchesId }"
            required
          >
            <option
              v-for="item of branchesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="clientsId" class="form-label required">
            اسم العميل
            <span>*</span>
          </label>

          <Dropdown
            :options="clientsList"
            class="form-select form-control text-right"
            style="direction:rtl"
            optionLabel="name"
            optionValue="id"
            :filter="true"
            v-model.number="body.clientsId"
            :class="{ 'is-invalid': isInvalid && !body.clientsId }"
            required
            placeholder="اسم العميل"
            :showClear="true"
          >
          </Dropdown>

          <!-- <select
            class="form-select form-control"
            id="clientsId"
            name="clientsId"
            v-model.number="body.clientsId"
            :class="{ 'is-invalid': isInvalid && !body.clientsId }"
            required
          >
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select> -->
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="countriesId" class="form-label required">
            الدوله
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="countriesId"
            name="countriesId"
            v-model.number="body.countriesId"
            :class="{ 'is-invalid': isInvalid && !body.countriesId }"
            required
          >
            <option
              v-for="item of countriesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="citiesId" class="form-label required">
            المدينه
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="citiesId"
            name="citiesId"
            v-model.number="body.citiesId"
            :class="{ 'is-invalid': isInvalid && !body.citiesId }"
            required
          >
            <option
              v-for="item of citiesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="address" class="form-label">
            موقع العميل
          </label>
          <input class="form-control" id="address" v-model="body.address" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="usersId" class="form-label required">
            مندوب المبيعات
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="usersId"
            name="usersId"
            v-model.number="body.usersId"
            :class="{ 'is-invalid': isInvalid && !body.usersId }"
            required
          >
            <option v-for="item of usersList" :key="item.id" :value="item.id">{{
              item.name
            }}</option>
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="deliveryDate" class="form-label required">
            اقصي تاريخ للتسليم
            <span>*</span>
          </label>

          <Calendar
            style="width: 100%;"
            id="deliveryDate"
            v-model="body.deliveryDate"
            :class="{ 'is-invalid': isInvalid && !body.deliveryDate }"
            :minDate="minDateValue"
            dateFormat="dd-mm-yy"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 text-right" style="direction:ltr">
          <label for="code" class="form-label text-right required">
            <span>*</span>
            قيمه العقد
          </label>

          <InputNumber
            id="horizontal"
            v-model="body.price"
            showButtons
            buttonLayout="horizontal"
            :step="1"
            min="0"
            :disabled="!canEdit"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            :class="{
              'is-invalid': isInvalid && !body.price && body.price == 0,
            }"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="taxesId" class="form-label required">
            الضريبه
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="taxesId"
            name="taxesId"
            v-model="body.taxesId"
            :disabled="!canEdit"
            :class="{ 'is-invalid': isInvalid && !body.taxesId }"
            required
          >
            <option v-for="item of taxesList" :key="item.id" :value="item">{{
              item.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3 ">
          <label for="total" class="form-label">
            الاجمالى
          </label>
          <input class="form-control" v-model="body.total" disabled />
        </div>
      </div>
      <!-- <div class="col-md-3"> -->
      <!-- <div class="mb-3 ">
          <label for="total" class="form-label">
            تفعيل العقد مباشر بدون السداد
          </label>
          <select
            class="form-select form-control"
            id="withoutPayment"
            name="withoutPayment"
            v-model="body.withoutPayment"
            :disabled="!$checkRoles('contractsWithoutPayment')"
          >
            <option :value="false">لا</option>
            <option :value="true">نعم</option>
          </select>
        </div> -->
      <!-- </div> -->

      <div class="col-md-12 row cdolog" v-if="canEdit">
        <div class="col-md-6">
          <DataTable
            ref="dt"
            :value="body.productsList"
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              <div class="table-header">
                قائمه الخدمات والمنتجات
                <Button
                  icon="pi pi-plus"
                  v-tooltip="'اضافه جديد'"
                  @click="displayProductsAdd = true"
                />
              </div>
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <Button
                  type="button"
                  icon="pi pi-trash"
                  v-tooltip="'حذف'"
                  @click="body.productsList.splice(slotProps.index, 1)"
                  class="p-button-warning  p-button-rounded"
                ></Button>
              </template>
            </Column>
          </DataTable>
          <Dialog
            header="اضافة المنتجات والخدمات"
            :visible.sync="displayProductsAdd"
            :style="{ width: '50vw', height: '90%' }"
          >
            <div class="p-m-0">
              <div class="mb-3 ">
                <label for="productsId" class="form-label required">
                  المنتج
                  <span>*</span>
                </label>
                <select
                  class="form-select form-control"
                  id="productsId"
                  name="productsId"
                  v-model.number="productsAdd.productsId"
                >
                  <option
                    v-for="item of productsList"
                    :key="item.id"
                    :value="item"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3 ">
                <label for="itemsId" class="form-label required">
                  الخدمه
                  <span>*</span>
                </label>
                <MultiSelect  dataKey="id"
                  v-model="productsAdd.itemsId"
                  :options="itemsList"
                  optionLabel="name"
                  display="chip"
                  style="width: 100%;"
                />
                <!-- <select
                  class="form-select form-control"
                  id="itemsId"
                  multiple
                  name="itemsId[]"
                  v-model.number="productsAdd.itemsId"
                >
                  <option
                    v-for="item of itemsList"
                    :key="item.id"
                    :value="item"
                    >{{ item.name }}</option
                  >
                </select> -->
              </div>
            </div>
            <template #footer>
              <Button
                label="اضافه"
                icon="pi pi-check"
                @click="addProducts(true)"
                autofocus
              />
              <Button
                label="الغاء"
                icon="pi pi-times"
                @click="addProducts(false)"
                class="p-button-text"
              />
            </template>
          </Dialog>
        </div>
        <div class="col-md-6">
          <DataTable
            ref="dt"
            :value="body.payments"
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              <div class="table-header">
                قائمه الدفعات
                <Button
                  icon="pi pi-plus"
                  v-tooltip="'اضافه جديد'"
                  @click="displayPayment"
                />
              </div>
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="name" header="اسم الدفعه" />
            <Column field="price" header="القيمه" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <Button
                  type="button"
                  icon="pi pi-trash"
                  v-tooltip="'حذف'"
                  @click="body.payments.splice(slotProps.index, 1)"
                  class="p-button-warning  p-button-rounded"
                ></Button>
              </template>
            </Column>
          </DataTable>
          <Dialog
            header="الدفعات"
            :visible.sync="displayPaymentAdd"
            :style="{ width: '50vw' }"
          >
            <div class="p-m-0">
              <div class="mb-3 ">
                <label for="code" class="form-label required">
                  اسم الدفعه
                  <span>*</span>
                </label>
                <input
                  class="form-control"
                  id="code"
                  v-model="paymentAdd.name"
                />
              </div>
              <div style="direction:ltr" class="mb-3 text-right">
                <label for="code" class="form-label text-right required">
                  <span>*</span>
                  المبلغ
                </label>
                <InputNumber
                  id="horizontal"
                  v-model="paymentAdd.price"
                  showButtons
                  buttonLayout="horizontal"
                  :max="paymentAddPriceMax"
                  min="0"
                  :step="1"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                />
              </div>
            </div>
            <template #footer>
              <Button
                label="اضافه"
                icon="pi pi-check"
                @click="addPayment"
                autofocus
              />
              <Button
                label="الغاء"
                icon="pi pi-times"
                @click="displayPaymentAdd = false"
                class="p-button-text"
              />
            </template>
          </Dialog>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3 ">
          <label for="files" class="form-label">
            مرفقات
          </label>
          <input
            class="form-control"
            type="file"
            id="files"
            multiple
            @change="previewImage"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label for="materialsNote" class="form-label required">
            تفاصيل المواد الخام
            <span>*</span>
          </label>

          <Editor v-model="body.materialsNote" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-align" value=""></button>
                <button class="ql-align" value="center"></button>
                <button class="ql-align" value="right"></button>
                <button class="ql-align" value="justify"></button>
              </span>
            </template>
          </Editor>
        </div>

        <div class="mb-3">
          <label for="note" class="form-label">
            ملاحظات
          </label>
          <b-form-textarea
            id="textarea-small"
            name="note"
            v-model="body.note"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        withoutPayment: false,
        date: `${ye}-${mo}-${da}`,
        clientsId: null,
        branchesId: null,
        usersId: null,
        deliveryDate: `${ye}-${mo}-${da}`,
        address: null,
        price: 0,
        total: 0,
        countriesId: null,
        citiesId: null,
        taxesId: null,
        productsList: [],
        payments: [],
        note: null,
        materialsNote: null,
      },
      paymentAddPriceMax: 0,
      productsAdd: {
        productsId: null,
        itemsId: [],
      },
      paymentAdd: {
        name: null,
        price: 0,
      },
      id: null,
      clientsList: [],
      branchesList: [],
      usersList: [],
      productsList: [],
      itemsList: [],
      displayProductsAdd: false,
      displayPaymentAdd: false,

      isInvalid: false,
      disabledAdd: false,
      canEdit: true,
      countriesList: [],
      citiesList: [],
    };
  },
  methods: {
    async previewImage(ev) {
      if (ev.target.files.length > 0) {
        const list = [];
        for (const item of ev.target.files) {
          const itm = await this.$toBase64(item);
          await list.push(itm);
        }
        this.body.files = await list;
      } else {
        this.body.files = [];
      }
      return await true;
    },
    save() {
      this.disabledAdd = true;
      if (
        this.body.date &&
        this.body.price > 0 &&
        this.body.taxesId &&
        this.body.clientsId &&
        this.body.branchesId &&
        this.body.usersId &&
        this.body.deliveryDate &&
        this.body.productsList.length > 0 &&
        this.body.payments.length > 0 &&
        this.body.materialsNote &&
        this.body.total ==
          this.body.payments.reduce((val, item) => {
            return val + item.price;
          }, 0)
      ) {
        // this.body.total = this.body.price + this.body.taxesId;
        this.$http.post(`contracts`, this.body).then(
          (res) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/sales/contracts');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.disabledAdd = false;
        this.isInvalid = true;
        if (
          this.body.total !=
          this.body.payments.reduce((val, item) => {
            return val + item.price;
          }, 0)
        ) {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'مجموع الدفعات يجيب ان يساوى الاجمالي',
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'يرجي اضافة الحقول المطلوبه',
            life: 3000,
          });
        }
      }
    },

    update() {
      if (
        this.body.date &&
        this.body.price > 0 &&
        this.body.taxesId &&
        this.body.clientsId &&
        this.body.branchesId &&
        this.body.usersId &&
        this.body.deliveryDate &&
        this.body.materialsNote
        // &&
        // this.body.total ==
        //   this.body.payments.reduce((val, item) => {
        //     return val + item.price;
        //   }, 0)
      ) {
        this.disabledAdd = true;
        delete this.body.payments;
        delete this.body.productsList;
        // this.body.total = this.body.price + this.body.taxesId;
        this.$http.put(`contracts/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            // this.$router.push('/admin/sales/contracts');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        if (
          this.body.total !=
          this.body.payments.reduce((val, item) => {
            return val + item.price;
          }, 0)
        ) {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'مجموع الدفعات يجيب ان يساوى الاجمالي',
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'يرجي اضافة الحقول المطلوبه',
            life: 3000,
          });
        }
      }
    },

    displayPayment() {
      this.displayPaymentAdd = true;
      const total = this.body.total;
      const oldTotal = this.body.payments.reduce((val, item) => {
        return val + item.price;
      }, 0);
      this.paymentAdd = {
        name: this.body.payments.length + 1,
        price: total - oldTotal,
      };
      this.paymentAddPriceMax = total - oldTotal;
    },
    addPayment() {
      if (this.paymentAdd.name && this.paymentAdd.price > 0) {
        this.body.payments.push(this.paymentAdd);
        this.displayPaymentAdd = false;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'املاء الحقول',
          life: 3000,
        });
      }
    },
    addProducts(condition) {
      if (condition) {
        if (
          this.productsAdd.productsId &&
          this.productsAdd.itemsId.length > 0
        ) {
          for (const item of this.productsAdd.itemsId) {
            console.log(item);
            this.body.productsList.push({
              sectionsId: item.sectionsId.id,
              productsId: this.productsAdd.productsId,
              itemsId: item,
            });
          }
          this.productsAdd = {
            productsId: null,
            itemsId: [],
          };
          this.itemsList = [];

          this.displayProductsAdd = false;
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'املاء الحقول',
            life: 3000,
          });
        }
      } else {
        this.productsAdd = {
          productsId: null,
          itemsId: [],
        };
        this.itemsList = [];
        this.displayProductsAdd = false;
      }
    },
  },

  created() {
    if (!this.$checkRoles('contractsAdd')) {
      this.$router.push('/admin/');
    }

    if (this.$checkRoles('contractsDate')) {
      const d = new Date();
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      this.minDateValue = new Date(`2000-${mo}-${da}`);
    }

    this.$http.get(`countries`).then((res) => {
      this.countriesList = res.data;
    });
    this.$http.get(`products`).then((res) => {
      this.productsList = res.data;
    });
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.$http.get(`taxes`).then((res) => {
      this.taxesList = res.data;
    });

    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;

      this.$http.get(`contracts/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.clientsId = this.body.clientsId.code;
          this.body.branchesId = this.body.branchesId.id;
          this.body.usersId = this.body.usersId.id;
          this.body.countriesId = this.body.countriesId.id;
          this.body.citiesId = this.body.citiesId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );

      this.$http
        .post(`materials/search`, {
          where: {
            contractsId: this.id,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.canEdit = false;
          }
        });
    }
  },
  watch: {
    'body.price'(val) {
      if (this.body.taxesId && this.body.taxesId.value) {
        this.body.total = val + (val / 100) * this.body.taxesId.value;
      }
    },
    'body.taxesId'(val) {
      this.body.total = this.body.price + (this.body.price / 100) * val.value;
    },
    'productsAdd.productsId'(val) {
      if (val) {
        this.$http.get(`items?productsId=${val.id}`).then((res) => {
          this.itemsList = res.data;
        });
      }
    },
    'body.countriesId'(val) {
      if (val) {
        this.$http.get(`cities?countriesId=${val}`).then((res) => {
          this.citiesList = res.data;
        });
      }
    },

    'body.clientsId'(val) {
      if (this.id) {
        const item = this.clientsList.find((el) => el.id == val);
        this.body.countriesId = item.countriesId.id;
        this.body.address = item.address;
        this.$http
          .get(`cities?countriesId=${item.countriesId.id}`)
          .then((res) => {
            this.citiesList = res.data;
            this.body.citiesId = item.citiesId.id;
          });
      }
    },
  },
};
</script>

<style>
.cdolog .p-dialog .p-dialog-content {
  height: 100%;
}

.form-control .p-dropdown-label.p-placeholder {
  padding: 0 !important;
}
.p-dropdown .p-dropdown-clear-icon {
  left: 2.357rem !important;
  right: auto !important;
}
</style>
